import React, { useContext, useState } from "react";
// import mobile_nav from '../../images/nav.svg'
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Sling as Hamburger } from "hamburger-react";
import Button from "../Button/Button";
import ModalContext from "../context/ModalContext";
import { MainHeader, ResponsiveHeader } from "./style";
import { useLocation } from "@reach/router";

interface Props {
  id?: string;
  logo_version?: string;
}

const IDXHeader: React.FC<Props> = ({ id, logo_version }) => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);
  const [visible, setVisible] = useState(false);
  const url =
    typeof window != "undefined"
      ? window.location.href
      : "https://homes.selbysellssd.com/idx/search/advanced";
  //const location = useLocation();
  const showDrawer = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(false);
  };
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <MainHeader id={id} className="absolute w-full top-0 z-a">
        <div className="py-8 border-b border-white">
          <div className="container">
            <div className="flex items-center justify-between md:flex-col">
              <div className="logo ">
                <Link to="/">
                  <img src={logo_version} alt="logo" />
                </Link>
              </div>

              <div className="flex items-center justify-between gap-4">
                <ul className="text-white text-d-3lg flex font-Quicksand font-[600]">
                  <li className="mx-16 lg:mx-10  hover:opacity-70">
                    <Link to="https://selbysellssd.com/buy-house-san-diego/">Buy</Link>
                  </li>
                  <li className="mx-16 lg:mx-10  hover:opacity-70">
                    <Link to="https://selbysellssd.com/sell-your-san-diego-home/">Sell</Link>
                  </li>
                  <li className="mx-16 lg:mx-10  hover:opacity-70">
                    <Link to="https://selbysellssd.com/san-diego-communities/">Communities</Link>
                  </li>
                  <li className="mx-16 lg:mx-10  hover:opacity-70">
                    <Link to="https://selbysellssd.com/san-diego-real-estate-agents/">Team</Link>
                  </li>
                  <li className="mx-16 lg:mx-10  hover:opacity-70">
                    <Link to="https://selbysellssd.com/testimonials/">Testimonials</Link>
                  </li>
                  <li className="mx-16 lg:mx-10  hover:opacity-70">
                    <Link to="https://selbysellssd.com/blogs/">Blog</Link>
                  </li>
                </ul>
                <Link
                  to={`https://selbysellssd.com/contact-form?idxurl=${url}`}
                  className="xs:max-w-[48%] xs:w-full mr-[16px] xs:mr-[0]"
                >
                  <Button type="solid" color="white" customStyle="px-42 ml-24 border border-black">
                    <p>Contact</p>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MainHeader>
      <ResponsiveHeader className={visible ? "active" : "hide"}>
        <div className="header_main_container border-b border-black bg-[#fff]">
          <div className="container">
            <div className="flex p-12 items-center justify-between">
              <div className="img img-left">
                <a href="tel:6192596482">
                  <StaticImage src="https://selbysellssd.com/images/phone.svg" alt="phone" />
                </a>
              </div>

              <div className="logo">
                <Link to="/">
                  <StaticImage
                    src="https://selbysellssd.com/images/Selby_blue_logo.svg"
                    alt="logomobile"
                  />
                </Link>
              </div>

              <div
                className={
                  visible ? "hamburger-close cursor-pointer" : "hamburger-open cursor-pointer"
                }
                onClick={showDrawer}
                id={visible ? "sm_menu_active" : "sm_menu_hide"}
              >
                <Hamburger size={23} toggled={isOpen} toggle={setOpen} />
              </div>
            </div>
          </div>
        </div>

        <div className={visible ? "responsive-menu active" : "responsive-menu hide"}>
          <ul className="font-IbarraRealNova mb-[100px]">
            <div className="sm_logo xs_small:mt-[50px]">
              <Link to="/">
                <StaticImage
                  src="https://selbysellssd.com/images/Selby_logo.svg"
                  alt="sellbylogo"
                />
              </Link>
            </div>
            <li className="text-sm-m font-[600] cursor-pointer mt-[20px] mb-[32px]">
              <Link to="https://selbysellssd.com/buy-house-san-diego">Buy</Link>
            </li>
            <li className="text-sm-m font-[600] cursor-pointer mb-[32px]">
              <Link to="https://selbysellssd.com/sell-your-san-diego-home">Sell</Link>
            </li>
            <li className="text-sm-m font-[600] cursor-pointer mb-[32px]">
              <Link to="https://selbysellssd.com/san-diego-communities">Communities</Link>
            </li>
            <li className="text-sm-m font-[600] cursor-pointer mb-[32px]">
              <Link to="https://selbysellssd.com/san-diego-real-estate-agents">Team</Link>
            </li>
            <li className="text-sm-m font-[600] cursor-pointer mb-[32px]">
              <Link to="https://selbysellssd.com/testimonials">Testimonials</Link>
            </li>
            <li className="text-sm-m font-[600] cursor-pointer mb-[32px]">
              <Link to="https://selbysellssd.com/blogs">Blog</Link>
            </li>
          </ul>
          <div className="w-full flex xs:justify-between xs:max-w-[100%] xs:w-full">
            <Link
              to="https://selbysellssd.com/contact-form"
              className="xs:max-w-[48%] xs:w-full mr-[16px] xs:mr-[0]"
            >
              <Button
                type="solid"
                color="black"
                customStyle="!bg-white !text-black px-[43px] xs:px-[0] xs:w-full"
              >
                <p>Contact</p>
              </Button>
            </Link>

            <Link to="#" className="xs:max-w-[48%] xs:w-full">
              <Button
                type="solid"
                color="white"
                customStyle="border border-white px-[43px] xs:px-[0] xs:w-full"
              >
                <p>Call us</p>
              </Button>
            </Link>
          </div>
        </div>
      </ResponsiveHeader>
    </>
  );
};

export default IDXHeader;
