import React, { FC, useState } from "react";
import ModalContext from "../context/ModalContext";
import ContantSlideOutModal from "../ContactSlideout/index";
import IDXFooter from "../IDXFooter/IDXFooter";
import IDXHeader from "../IDXHeader/IDXHeader";
interface LayoutPropsType {
  children?: JSX.Element;
  headerLogo?: string;
  headerId?: string;
  title?: string;
  desc?: string;
}
const IDXWrapper: FC<LayoutPropsType> = ({ children, headerLogo, headerId, desc, title }) => {
  const [popupContent, setPopupContent] = useState("steps");
  const [modalvisible, setModalvisible] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        popupContent,
        setPopupContent,
        setModalvisible,
        modalvisible,
      }}
    >
      <title>{title}</title>
      <meta name="description" content={desc} />
      <IDXHeader id={headerId || ""} logo_version={headerLogo} />
      {modalvisible && <ContantSlideOutModal />}
      {children}
      <IDXFooter />
    </ModalContext.Provider>
  );
};

export default IDXWrapper;
