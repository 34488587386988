import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { DownArrowIcon } from "../Icons/down-arrow";
import { communitiesData } from "../FeaturedCommunities/FeaturedCommunitiesData";
import selbyLogo from "../../images/Selby_logo.webp";
import fbLogo from "../../images/fb.webp";
import instagramLogo from "../../images/instagram.webp";
import youtubeLogo from "../../images/youtube.webp";
import nestLogo from "../../images/nest_logo.png";

const citiesData = [
  {
    tag: "Del Mar",
    url: "/del-mar-homes-for-sale/",
  },
  {
    tag: "La Jolla",
    url: "/la-jolla-homes-for-sale/",
  },
  {
    tag: "Ocean Beach",
    url: "/ocean-beach-homes-for-sale/",
  },
  {
    tag: "Rolando",
    url: "/rolando-homes-for-sale/",
  },
  {
    tag: "Scripps Ranch",
    url: "/scripps-ranch-homes-for-sale/",
  },
  {
    tag: "Solana Beach",
    url: "/solana-beach-homes-for-sale/",
  },
];
const IDXFooter = () => {
  const [commList, setCommList] = useState(false);
  const [citiesList, setCitiesList] = useState(false);
  return (
    <div className="bg-[#0D1733] pt-[69px] pb-[24px] text-[#fff] font-Quicksand md:pt-[36px]">
      <div className="container">
        <div className="flex flex-wrap items-start justify-start md:mb-[90px] sm:flex-col">
          <ul className="mr-[259px] mb-[40px] 2xs:mr-[0px] md:mb-[0px]">
            <li className="pb-[19px] md:pb-[15px]">
              <Link to="https://selbysellssd.com/">
                <img src={selbyLogo} alt="sellbylogo" />
              </Link>
            </li>
            <li className="font-Quicksand text-d-2lg font-[700] md:mb-[0px]">
              DRE# 01772626
              <br />
              Broker# 01527365
            </li>

            <ul className="flex gap-[26px] mb-[47px] invisible md:visible md:mb-[0px] py-[45px]">
              <li>
                <Link to="https://www.facebook.com/TheSelbyTeam/" target="_blank">
                  <img src={fbLogo} alt="facebook" />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/selbysellssandiego/" target="_blank">
                  <img src={instagramLogo} alt="instagram" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCVAle6ICSXxZy9vut_Q580g/featured"
                  target="_blank"
                >
                  <img src={youtubeLogo} alt="youtube" />
                </Link>
              </li>
              {/*} <li>
                <Link to="https://www.tiktok.com" target="_blank">
                  <StaticImage src="../../images/tiktok.svg" alt="tiktok" />
                </Link>
              </li>
              <li>
                <Link to="https://www.twitter.com" target="_blank">
                  <StaticImage src="../../images/twitter.svg" alt="twitter" />
                </Link>
              </li>*/}
            </ul>
          </ul>

          <ul className="mr-[50px] sm:mr-[50px] mb-[40px] 2xs_extar:mr-[52px]">
            <li className="text-d-3lg font-[600] mb-[24px]">Explore</li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="https://selbysellssd.com/buy-house-san-diego">Buy</Link>
            </li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="https://selbysellssd.com/sell-your-san-diego-home">Sell</Link>
            </li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="https://selbysellssd.com/san-diego-communities">Communities</Link>
            </li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="https://selbysellssd.com/real-estate-marketing-services">
                Compass Concierge
              </Link>
            </li>
          </ul>

          <ul className="mr-[50px] mb-[40px] 2xs_extar:mr-[0px]">
            <li className="text-d-3lg font-[600] mb-[24px]">Company</li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="https://selbysellssd.com/san-diego-real-estate-agents">About Us</Link>
            </li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="https://selbysellssd.com/testimonials">Testimonials</Link>
            </li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="https://selbysellssd.com/blogs">Blog</Link>
            </li>
          </ul>

          <ul>
            <li className="text-d-3lg font-[600] mb-[24px]">Contact</li>
            <li className="text-d-2lg font-[400] mb-[24px] opacity-70">
              The Selby Team
              <br />
              8889 Rio San Diego Drive, Suite 200
              <br />
              San Diego, CA, 92108
            </li>
            <li className="text-d-2lg font-[400] mb-[24px] opacity-70">
              <Link to="tel:6192596482">(619) 259-6482</Link>
            </li>
            <li className="text-d-2lg font-[400] mb-[12px] opacity-70">
              <Link to="mailto:team@selbysellssandiego.com">team@selbysellssandiego.com</Link>
            </li>
          </ul>
        </div>

        <div className="flex items-center justify-between md:justify-center">
          <ul className="flex items-center gap-[16px] xs:flex-col md:flex-wrap md:justify-center relative">
            <li className="text-d-2lg font-[400] opacity-70">
              <Link to="#">©{new Date().getFullYear()} The Selby Team</Link>
            </li>
            <li className="text-d-2lg font-[400] opacity-70">
              <Link to="https://selbysellssd.com/privacy-policy/">Privacy Policy</Link>
            </li>
            <li className="text-d-2lg font-[400] 2xs:hidden">
              <span
                className="cursor-pointer flex items-center gap-[5.3px]"
                onClick={() => setCommList(!commList)}
              >
                <p className="opacity-70">Communities</p>
                <span className={commList ? "rotate-180" : "rotate-0"}>
                  <DownArrowIcon />
                </span>
                {commList && (
                  <ul className="absolute min-w-[100px] right-[175px] bottom-[28px] h-[100px] bg-white overflow-y-scroll font-Quicksand py-[4px] px-[10px] font-[500]">
                    {communitiesData?.map((item) => {
                      return (
                        <li className="text-black py-[4px] font-[600] capitalize" key={item.tag}>
                          <a href={item.url}>{item.tag}</a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </li>
            <li className="text-d-2lg font-[400] 2xs:hidden">
              <span
                className="cursor-pointer flex items-center gap-[5.3px]"
                onClick={() => setCitiesList(!citiesList)}
              >
                <p className="opacity-70">Cities</p>
                <span className={citiesList ? "rotate-180" : "rotate-0"}>
                  <DownArrowIcon />
                </span>
                {citiesList && (
                  <ul className="absolute min-w-[100px] right-[175px] bottom-[28px] h-[100px] bg-white overflow-y-scroll font-Quicksand py-[4px] px-[10px] font-[500]">
                    {citiesData?.map((item) => {
                      return (
                        <li className="text-black py-[4px] font-[600] capitalize" key={item.tag}>
                          <a href={item.url}>{item.tag}</a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </span>
            </li>
            <li className="text-d-2lg font-[400] flex items-center">
              <span className="mr-[7.7px] opacity-70">Powered by</span>
              <Link to="https://www.wisedigitalpartners.com/nest-by-wise/" className="opacity-70">
                <img src={nestLogo} alt="nestLogo" />
              </Link>
            </li>
          </ul>

          <ul className="flex gap-[26px] md:invisible md:absolute">
            <li>
              <Link to="https://www.facebook.com/TheSelbyTeam/" target="_blank">
                <img src={fbLogo} alt="facebook" />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/selbysellssandiego/" target="_blank">
                <img src={instagramLogo} alt="instagram" />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.youtube.com/channel/UCVAle6ICSXxZy9vut_Q580g/featured"
                target="_blank"
              >
                <img src={youtubeLogo} alt="youtube" />
              </Link>
            </li>
            {/*}
            <li>
              <Link to="https://www.tiktok.com" target="_blank">
                <StaticImage src="../../images/tiktok.svg" alt="tiktok" />
              </Link>
            </li>
            <li>
              <Link to="https://www.twitter.com" target="_blank">
                <StaticImage src="../../images/twitter.svg" alt="twitter" />
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IDXFooter;
