import React from "react";
import IDXWrapper from "../component/IDXWrapper/Index";

const Wrapper = () => {
  return (
    <IDXWrapper
      title="San Diego Real Estate listings | The Selby Team"
      desc="When you buy a house in San Diego, it's easy to love where you live. Search the latest listings and call today to speak with a buyer's agent at The Selby Team."
      headerLogo="https://selbysellssd.com/images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="container">
        <div className="pt-[100px] min-h-[500px]">
          <div id="idxStart"></div>
          <div id="idxStop"></div>
        </div>
      </div>
    </IDXWrapper>
  );
};

export default Wrapper;
