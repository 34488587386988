import styled from 'styled-components'

const MainHeader = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const ResponsiveHeader = styled.div`
  display: none;
  position: relative !important;
  @media (max-width: 768px) {
    display: block !important ;
  }

  .responsive-menu.active {
    width: 100%;
    display: block;
    color: #fff;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999;
    padding: 20px 28px 80px 28px;
    background-color: #0d1733;
  }

  @media only screen and (max-width: 370px) {
    .responsive-menu.active {
      padding-top: 0 !important;
    }
  }

  .responsive-menu.hide {
    display: none;
  }

  .cursor-pointer {
    z-index: 999999;
  }

  #sm_menu_active img {
    filter: brightness(0) invert(1);
  }

  .sm_menu_btn {
    width: 100%;
    max-width: calc(100% / 2 -30px);
  }

  .hamburger-open {
    width: 30px !important;
    .hamburger-react {
      color: #063E5C !important;
    }
  }

  .hamburger-close {
    margin-top: 26px;
    position: fixed;
    right: 40px;
    margin-right: -23px;
  }

  @media only screen and (max-width: 370px) {
    .hamburger-close  {
      right: 37px;
      margin-top: 0 !important;
    }
  }

  .hamburger-open {
    .hamburger-react {
      color: #000;
    }
  }

  .hamburger-close {
    .hamburger-react {
      color: #fff;
    }
  }
`

const V1_btn = styled.button`
  &:hover i img {
    filter: brightness(1) invert(1);
  }
`

export { V1_btn, MainHeader, ResponsiveHeader }
